@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root 
.app {
    height: 100%;
}

.app-container {
    position: relative;
}
